import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide81/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide81/image/img.png"
import slider1 from "@components/pageGuide/guides/guide81/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide81/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide81/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Concesionarias",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo automatizar el seguimiento postventa en concesionarias de autos
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      El seguimiento postventa es clave para mantener el contacto con clientes. Automatiza recordatorios de mantenimiento y servicios según el historial de cada vehículo. Configura mensajes programados para ofrecer información sobre garantías y beneficios adicionales.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Encuestas de satisfacción automatizadas.",
      },
      {
        key: 2,
        text: " Seguimiento de quejas y solicitudes de garantía."
      },
      {
        key: 3,
        text: "Integración con chatbots para una atención 24/7.",
      },
      {
        key: 4,
        text: "Gestión de citas automatizadas para talleres.",
      },
      {
        key: 5,
        text: "Automatización de recordatorios de mantenimiento y servicios.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo gestionar encuestas de satisfacción y recopilar datos para mejorar la experiencia del cliente. Asegura respuestas rápidas con chatbots y canales digitales integrados.
          <br /><br />
          Por último, accede a más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Optimiza el proceso y mejora la relación con los clientes.</p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
